<template>

  <div class="card mb-4">
    <div class="card-header pb-0">
      <h2 :class="`${form.uuid?'mb-0':'mb-4'}`">{{form.uuid? 'OTD' : 'OTD FORM'}}</h2>

      <div v-if="!registered">
        <div v-if="form.uuid" class="mb-3">{{form.hash}}</div>

        <SoftAlert
          v-if="messages && messages.success === true"
          icon="fas fa-thumbs-up" :message="this.messages.text" />
        <SoftAlert
          v-if="messages && messages.success === false"
          icon="fas fa-thumbs-up" :message="this.messages.text" color="danger" />
          
        <div class="row">
          <div :class="`${form.uuid?'col-6':'col-12'}`">
            <form @submit.prevent="submitForm">

              <div class="mb-3">
                <label for="title" class="form-label">Title:</label>
                <input type="text" name="title" class="form-control" v-model="form.title" required placeholder="Document Title" :disabled="loading || form.uuid">
              </div>

              <div class="mb-3" >
                <label for="document" class="form-label">Document:</label>
                <input type="file" name="document" class="form-control" required @change="onFileChange" accept="application/pdf" v-if="!form.uuid" :disabled="loading || form.uuid">
                <input type="text" name="title" class="form-control" v-model="form.file_name" v-else disabled>

              </div>

              <div class="mb-3">
                <label for="description" class="form-label">Description:</label>
                <textarea  name="description" class="form-control" v-model="form.description" placeholder="(Optional) Document description" :disabled="loading || form.uuid"></textarea>
              </div>

              <div class="mb-3" v-if="form.uuid">
                <label for="created_at" class="form-label">Created At:</label>
                <input type="text" name="title" class="form-control" v-model="form.created_at" disabled>
              </div>       

              <button v-if="!form.uuid"
                :disabled="loading"
                class="btn bg-gradient-warning mt-4 w-50"
                type="submit"
              ><i class="fas fa-plus"> </i> Create</button
              >

              <router-link
                :disabled="loading"
                :to="{ name: 'One True Data' }"
                :class="`btn mt-4 ${!form.uuid? 'bg-gradient-faded w-50':'bg-gradient-warning w-100'}`"
              >
                <i class="fa fa-close"> </i> Back
              </router-link>

            </form>
          </div>

          <div class="col-6" v-if="form.uuid">
            
            <form @submit.prevent="submitFormEvent">
              <label for="receiverUser" class="form-label">Add Event:</label>
              <select id="receiverUser" v-model="formEvent.oth_token" required class="form-select mb-2">
                    <option selected disabled :value="null">Select a OTH Token</option>
                    <option v-for="(t,index) in othTokens" :key="index" :value="t.key">
                      {{t.name}} - {{ t.title }} - {{ t.token }}
                    </option>
                  </select>
              <div class="d-flex">
                <div class="me-2 w-100" >               
                  <select id="receiverUser" v-model="formEvent.event" required class="form-select">
                    <option selected disabled :value="null">Select a OTD Event</option>
                    <option v-for="(event,index) in otdEvents" :key="index" :value="event.key">
                      {{ event.title }}
                    </option>
                  </select>
                </div>
                <button
                  :disabled="loading || !formEvent.event"
                  class="btn bg-gradient-warning w-15"
                  type="submit"
                >Ok</button>
              </div>
            </form>

            <!-- <pre>{{form.events}}</pre> -->

            <label for="document" class="form-label">Current events:</label>

            <div v-if="form.events && form.events.length>0">


              <!-- <div class="d-flex">
                <div><i class='fa fa-file-o me-3' style="font-size:50px" ></i></div>
                <div class="d-block">
                  <div class='font-weight-bold'>{{form.events.file? form.events.file : '- - -'}}</div>
                  
                  <div class='font-weight-bold small'>
                    <div v-if="form.events.status=='ok'"><i class='fa fa-tag text-success me-2'></i>{{form.events.registered? form.events.registered : '- - -'}}</div>
                    <div v-else><i class='fa fa-tag text-danger me-2'></i> Is not registered on Trakk, or has been tampered with.</div>                           
                  </div>
                </div>
              </div> -->

              <TimelineList>

                <TimelineItem v-for="(event, e_index) in form.events" :key="e_index"
                  :color="event.color"
                  :icon="event.icon"
                  :title="event.title"
                  :date-time="event.date_time"
                />
                
              </TimelineList>
            </div>
            <div v-else class="text-center">
              No events found...
            </div>


          </div>
        </div>

        
      
      </div>
      <div v-else>

        <p>Document successfully registered, to download click <a :href="download_link" target="_blank">here</a>.</p>

        <button
          class="btn bg-gradient-warning mt-4 w-50"
          @click="newDocument()"
        ><i class="fas fa-plus"> </i> New</button>

        <router-link
          :to="{ name: 'One True Data' }"
          :class="`btn mt-4 ${!form.uuid? 'bg-gradient-faded w-50':'bg-gradient-warning w-100'}`"
        >
          <i class="fa fa-close"> </i> Exit
        </router-link>
        
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SoftAlert from "../../../components/SoftAlert.vue";
import TimelineList from "../../components/TimelineList.vue";
import TimelineItem from "../../components/TimelineItem.vue";

export default {
  name: "HybridTokensForm",
  components: { SoftAlert, TimelineList, TimelineItem },
    data() {
    return {
      loading: false,

      download_link: null,
      registered: false,

      form: {
        created_at: null, 
        deleted_at: null, 
        description: null, 
        extension: null, 
        file_name: null, 
        hash: null, 
        title: null, 
        updated_at: null, 
        uuid: null,
        events: []
      },

      formEvent: {
        oth_token:null,
        event: null
      },

      otdEvents: [
        { key: 'Signed by',          title: 'To sign' },
        { key: 'Acknowledged by', title: 'To recognize' },
        { key: 'Financed by',     title: 'Finance' },
        { key: 'Alert by',        title: 'Alert' },
      ],

      othTokens: [
        { name: 'mariana.lemos', title: 'My Token 2', token: 'MjAyNDAyMjItNGE4NTNhMjEtMjJkYi00YmJmLTg4OTgtM2Q1MmY4NjMwNDI1'},
        { name: 'mariana.lemos', title: 'My Token 1', token: 'MjAyNDAyMjItMzBhNmJlOGItYjQwYy00NmFkLTg2N2YtZDhhMWRjYTBhMzc0'}
      ],




      file: null,
      messages: {}
    };
  },
  mounted() {
    this.handleLoadOtd()
  },
  methods: {
    newDocument() {
      this.download_link = null;      
      this.form.title = null;
      this.form.description = null
      this.file = null;
      this.messages = {};
      this.registered = false;      
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${month}/${day}/${year} ${hours}:${minutes}`;
    },

    async handleLoadOtd() {

      let self = this;
      if (!self.$route.params.uuid) {
        return;
      }

      await axios.get(`https://core-bastion.codeloop.co/api/otd/${self.$route.params.uuid}/show`).then(response => {
        if(response.data.status == 'success') {
          self.form             = response.data.data;
          self.form.created_at  = self.formatDate(self.form.created_at );

          // self.form.events = {
            
          //     file: 'Example file test.pdf',
          //     registered: 'Registered on 22 Jan 2024 by CodeLoop',
          //     status: 'ok',
          //     time_line: [
          //       { color: "danger",  icon:"check-bold",  title:"Alert by",                               date_time:"21 DEC 9:34 PM" },
          //       { color: "info",    icon:"check-bold",  title:"Financed by",                            date_time:"21 DEC 9:30 PM" },
          //       { color: "info",    icon:"check-bold",  title:"Acknowledged",                           date_time:"18 DEC 4:54 AM" },
          //       { color: "info",    icon:"check-bold",  title:"Registered on 22 Jan 2024 by CodeLoop",  date_time:"22 JAN 2024" }
          //     ]
          //   }




        } else {
          self.messages.text = response.data.message;
          self.messages.success = false;
        }          
      }).catch((error) =>{
        console.log(error);
        self.messages.success = false;
        self.messages = 'Error processing, please try again or contact support';
      }).finally(
        self.loading = false
      );


    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.file = null;
      } else {
        this.file = files[0];
      }
    },

    async submitFormEvent() {
      console.clear()

      let self = this;     
      self.loading = false;

      self.formEvent.otd = self.form.uuid;

      await axios.post(`https://core-bastion.codeloop.co/api/otd-event-store`, self.formEvent).then(response => {

        if(response.data.status == 'success') {

          self.formEvent.event = null;
          self.handleLoadOtd();
         
        } else {
          self.messages.text = response.data.message;
          self.messages.success = false;
        }          
      }).catch((error) =>{
        console.log(error);
        self.messages.success = false;
        self.messages = 'Error processing, please try again or contact support';
      }).finally(
        self.loading = false
      );
   
    },

    async submitForm() {

      console.clear()
      let self = this;     
      self.loading = false;
      self.registered = false;

      const formData2 = new FormData()
      formData2.append('title', self.form.title);
      formData2.append('description', self.form.description);
      formData2.append('file', self.file);

      await axios.post(`https://core-bastion.codeloop.co/api/otd-create`, formData2).then(response => {

        if(response.data.status == 'success') {

          self.messages.text = response.data.message;
          self.messages.success = true;
          self.download_link = response.data.download_link;
          self.registered = true;
          
          // self.$router.push({ path: "/add-otd" });
        } else {
          self.messages.text = response.data.message;
          self.messages.success = false;
        }          
      }).catch((error) =>{
        console.log(error);
        self.messages.success = false;
        self.messages = 'Error processing, please try again or contact support';
      }).finally(
        self.loading = false
      );
      
    },
  },

};
</script>